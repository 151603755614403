import { EnviromentZone, HowdenLoginBehaviour, HowdenLoginKind, IHowdenEnvironment, LogLevel } from '@howdeniberia/core-front';

export const environment = {
  zone: EnviromentZone.PROHOWDENGROUP,
  appIdSecurity: 3,
  emailError: 'soporte@howdeniberia.com',
  loginBehaviour: HowdenLoginBehaviour.REDIRECT,
  loginKind: HowdenLoginKind.INTERNAL,
  howdenLoginUrl: 'https://how-login.app.howdeniberia.com',
  logLevel: LogLevel.INFO
} as IHowdenEnvironment;
